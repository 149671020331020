import React, { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useInput } from '../../hooks/useInput';
import $api from '../../http';
import { RoundedButton } from '../common/RoundedButton/roundedButton';
import { ArrowRightIcon, ShareIcon } from '@heroicons/react/24/outline';
import TopImage from '../../assets/img/subsoccer_landing_image.jpeg'
import {
  AccountButton,
  AccountButtons,
  AccountFindTournaments,
  AccountInner,
  AccountTournamentButton,
  AccountTournamentId,
  AccountWrapper,
} from './Account.styled';
import { InputWithButton } from '../common/inputWithButton/inputWithButton';
import { NavLink } from 'react-router-dom';

export const Account: FC = () => {
  const navigate = useNavigate();
  const code = useInput('')
  const [error, setError] = useState<string>('');
  const codeButtonHandlerClick = () => {
    $api
      .get(`/tournaments/code/${code}`)
      .then(({ data }) => {
        navigate(data.link);
      })
      .catch(({ response }) => {
        setError(response?.data.message);
      });
  };

  return (
    <AccountWrapper>
      <AccountInner>
        <AccountButtons>
          <RoundedButton style={{backgroundColor: "red"}} text="Create tournament" onClick={() => navigate('/create')}>
            <ShareIcon className=' ml-5 h-8 w-8 text-white'></ShareIcon>
          </RoundedButton>
          <h2 className='text-center mt-10 mb-5' style={{fontSize:"25px"}}>Join tournament</h2>
          <InputWithButton 
          errorString={error} 
          placeholder={"Tournament id"} 
          onClick={codeButtonHandlerClick}
          icon={<ArrowRightIcon className="h-8 w-8 text-black"></ArrowRightIcon>}
          type="text"
          value={code.value}
          onChange={code.onChange}
          />
          {/* <AccountButton
            color="linear-gradient(90deg, rgba(24,217,124,1) 4%, rgba(68,105,130,1) 52%, rgba(47,138,168,1) 67%, rgba(79,76,131,1) 100%)"
            onClick={() => navigate('/create')}
            style={{ fontStyle: 'italic' }}
          >
            Create Tournament
          </AccountButton> */}
          {/* <AccountButton
            color="linear-gradient(90deg, rgba(146,30,118,1) 31%, rgba(121,35,115,1) 58%, rgba(89,40,119,1) 80%)"
            onClick={() => navigate('/tournament')}
            style={{ fontStyle: 'italic' }}
          >
            My tournaments
          </AccountButton> */}
          {/* <AccountButton
            color="linear-gradient(90deg, rgba(146,30,118,1) 31%, rgba(121,35,115,1) 58%, rgba(89,40,119,1) 80%)"
            onClick={() => navigate('/profile')}
            style={{ fontStyle: 'italic' }}
          >
            My Profile
          </AccountButton> */}
        </AccountButtons>
        <NavLink to="/tableregistration">register table</NavLink>
        <AccountFindTournaments>
          {/* {error && error}
          <AccountTournamentId
            type="number"
            onChange={code.onChange}
            placeholder="Tournament ID"
            min={1}
          />
          <AccountTournamentButton
            onClick={codeButtonHandlerClick}
            style={{ fontStyle: 'italic' }}
          >
            Find Tournament
          </AccountTournamentButton> */}
        </AccountFindTournaments>
      </AccountInner>
    </AccountWrapper>
  );
};
